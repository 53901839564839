.MCurriculumSection{
    background-color: rgb(60, 60, 60);
    width: 100%;
    margin-bottom: 30px;

}

.MenteeSections{
    overflow-y: scroll;
    margin-top: 70px;
    width:100%;
    height:100%;
    padding-bottom:200px;
}

.SectionTitle{
    background-color: rgb(100, 100, 100);
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 20px;
    height:50px;
}

.MenteeModal {
    position: fixed;
    z-index: 99999;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    width: 80%;
    height: 80%;
}

.ModalOverlay {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    color: rgb(223, 31, 31);
    z-index: 999;
}

.QSelector{
    position: absolute;
    left:50%;
    transform:translate(-50%, 0%);
    top: 70px;
}

.SComplete{
    color:rgb(13, 177, 13);
    position:relative;
    margin-left:10px;
}

.OpenEscrowModal {
    position:absolute;
    top:130px;
    box-shadow: 0px 0px 5px rgb(175, 175, 175);
    padding: 20px;
    background-color: rgb(52, 52, 52);
}

.CloseEscrowModal {
    position: absolute;
    top: 120px;
    left:50%;
    transform:translate(-50%,0%);
    box-shadow: 0px 0px 5px rgb(175, 175, 175);
    padding: 20px;
    background-color:rgb(52, 52, 52);
}

.EscrowRow {
    display:flex;
    align-items: center;
    justify-content: left;
    height: 40px;
    background-color: rgb(54, 54, 54);
    margin:5px;
    border-style: solid;
    border-width: 1px;
}

.EscrowRowColumn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left:10px;
    border-right-style: solid;
    border-right-width: 1px;
}

.NotesList {
    width:300px;
    height:400px;
    padding: 5px;
    overflow-y: scroll;
    background-color: rgb(82, 82, 82);
}

.NotesModal {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 20px;
    background-color: rgb(52, 52, 52);
}

.NoteRow{
    display: flex;
    align-items: center;
    justify-content: left;
    height: 40px;
    background-color: rgb(54, 54, 54);
    margin-top: 5px;
    padding-left: 10px;
}