.ChecklistItems{
    border-color: rgb(77, 77, 77);
    border-style: solid;
    border-width: 1px;
    max-height:300px;
    min-height: 100px;
    overflow-y: scroll;
}

.CheckItem {
    background-color: rgb(114, 114, 114);
    height:40px;
    margin:10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.AccordionPH{
    border-radius: 10px;
}

.AYSModal{
    position: absolute;
    background-color: rgb(58, 58, 58);
    text-align: center;
    padding: 20px;
    box-shadow: 0px 0px 10px rgb(119, 119, 119);
    right:20px;
    bottom:20px;
    z-index: 1000;
}

.moveAccordion {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 5px;
    padding:5px;
    background-color: rgb(63, 63, 63);
    border-radius: 10px;
    border-width: 1px;
    width:120px
}

.CAccordion{
    border-style: solid;
    border-color: rgb(65, 65, 65);
    border-width: 1px;
}

.ArrowMove:hover{
    cursor: pointer;
    color:rgb(179, 179, 179)
}