.ListRow {
    z-index: 10;
}

.ListRow:hover{
    cursor: pointer;
}

.BringUp{
    z-index: 99999;
}

.AddNewModal{
    position:fixed;
    z-index: 4000;
    top:50vh;
    left:50vw;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
}

.AreYouSureModal{
    position:absolute;
    z-index: 4000;
    top:50vh;
    left:50vw;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px rgb(194, 194, 194);
    text-align: center;
    padding: 20px;
    background-color: rgb(61, 61, 61);
}

/* .MenteeModal {
    position: fixed;
    z-index: 99999;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    width:80%;
    height:80%;
}

.ModalOverlay {
    position: fixed;
    top:0%;
    left:0%;
    width:100vw;
    height:100vh;
    color:rgb(223, 31, 31);
    z-index: 999;
} */