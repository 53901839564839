.popup-blue {
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
}

@media (min-width: 727px) {
    .hide-on-small {
        display: block;
    }
    .show-on-small {
        display: none;
    }
}

@media (max-width: 726px) {
    .hide-on-small {
        display: none;
    }
    .show-on-small {
        display: block;
    }
    .tbanner {
        clip-path: none !important;
        width: 100vw !important;
    }
    .topbio{
        text-align: center !important;
    }
}

.outlined-text {
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    /* Define the width and color of the outline */
    color: transparent;
    /* Make the text color transparent */
    font-size: 40px;
    /* Example font size */
    font-weight: bold;
    /* Bold font weight makes the outline more prominent */
}

/* 
.sign-up-btn {
    background: rgb(196, 6, 6);
    z-index: 9999999;
    position: relative;
    height:50px;
    width:90px;
    top:40vh;

} */


.sign-up-btn:hover{
    cursor: pointer;
    z-index: 9999999;
}