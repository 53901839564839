.viewer-card {
    display: flex;
    align-items: center;
    width: 300px;
    height: 25px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #333333;
    transition: all 0.2s ease-in-out;
    margin:20px;
}

.viewer-card-right {
    margin-left:auto;
}