.CloseWindow {
    position:absolute;
    top:5px;
    right:5px;
}

.CloseWindow:hover {
    color:rgb(113, 113, 113);
    cursor: pointer;
}

.OManagerList {
    width: 300px;
    height: 300px;
    background-color: rgb(47, 47, 47);
    border-radius: 5px;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.OManager {
    width: 280px;
    height:50px;
    background-color: rgb(90, 90, 90);
    margin:auto;
    margin-top: 5px;
    display:flex;
    align-items: center;
    padding:10px;
}