.MassEmailModal {
    position: fixed;
    z-index: 99999;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    width: 450px;
    height: 250px;
    box-shadow: 0px 0px 300px rgb(19, 19, 19);
}

.CloseModal {
    position: absolute;
    right:0;
    margin-right:10px;
}

.CloseModal:hover {
    cursor: pointer;
    color: rgb(116, 116, 116);
}