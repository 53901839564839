.paid-row {
    background-color: #16500e;
    /* green color for paid rows */
}

.paid-row:hover {
    background-color: #195a10 !important;
    /* slightly lighter green color for paid row hover */
}

.unpaid-row {
    background-color: #262626;
    /* white color for unpaid rows */
}